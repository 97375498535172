<template>
  <Container size="small">
    <BlockQuote :content="text" :color="color" :align="align" />
  </Container>
</template>

<script>
  import { AlchemyElement } from "~/mixins/alchemyVue"

  export default {
    mixins: [AlchemyElement],
    computed: {
      align() {
        return this.getValue("text_alignment")
      },
      text() {
        return this.getValue("text")
      },
      color() {
        return this.getValue("color")
      },
    },
  }
</script>
